<template>
  <div class="bg-gray-100">
    <div class="w-full bg-slate-100">
      <section class="flex justify-center w-full h-20 text-center shadow-xl">
        <div>
          <h2 class="text-2xl mx-4 md:mx-16 pt-8">{{ hmo.name }}</h2>
          <!-- {{ token }} pp  -->
        </div>
      </section>

      <div class="flex">
        <div class="card mx-auto mt-8 w-[90%] md:w-[45%]">
          <form @submit.prevent="validateForm">
            <div class="mb-6">
              <p class="balance-subtitle"></p>
            </div>
            <div class="flex flex-col space-y-3">
              <div class="form-row">
                <select
                  placeholder="Select Customer Type"
                  class="form-control auth-form"
                  v-model="form_data.new_customer"
                >
                  <option class="py-3" :value="1">New Customer</option>

                  <option class="py-3" :value="0">Existing Customer</option>
                </select>

                <!-- <select
                  placeholder="Select Plan"
                  class="form-control auth-form"
                  v-model="form_data.payment_method"
                  @change="getMinAmount"
                >
                  <option class="py-3" value="Full Payment">
                    Full Payment
                  </option>

                  <option class="py-3" value="Installment">Installment</option>
                </select> -->

                <div v-if="form_data.new_customer == '1'">
                  <div class="flex flex-row space-x-2 my-2">
                    <input
                      type="text"
                      required
                      @keyup="validateForm"
                      class="form-control auth-form-new"
                      placeholder="First Name"
                      v-model="user.firstname"
                    />

                    <input
                      type="text"
                      required
                      @keyup="validateForm"
                      class="form-control auth-form-new"
                      placeholder="Last Name"
                      v-model="user.lastname"
                    />
                  </div>

                  <div class="flex flex-row space-x-2 my-2">
                    <input
                      required
                      type="text"
                      pattern="\d*"
                      maxlength="11"
                      title="Phone number must be exactly 11 digits"
                      @keyup="validateForm"
                      class="form-control auth-form-new"
                      placeholder="Phone Number"
                      v-model="user.phone_number"
                    />

                    <input
                      type="email"
                      @keyup="validateForm"
                      class="form-control auth-form-new"
                      placeholder="E-mail"
                      v-model="user.email"
                    />
                  </div>
                </div>

                <div v-else>
                  <input
                    type="text"
                    class="form-control auth-form"
                    placeholder="Phone Number / Email"
                    @keyup="searchUser"
                    v-model="form_data.searchkey"
                  />
                  <p
                    class="error-search"
                    v-if="user.full_name == '' && form_data.searchkey != null"
                  >
                    user not found!
                  </p>
                  <p class="success-search" v-if="user.full_name">
                    user found successfully <i class="las la-check"></i>
                  </p>
                </div>
              </div>

              <input
                v-if="form_data.new_customer == '0'"
                type="text"
                disabled
                class="form-control auth-form bg-slate-100"
                placeholder="Customer Full Name"
                :value="user.full_name"
              />

              <select
                placeholder="Select Plan"
                class="form-control auth-form"
                v-model="form_data.plan"
                @change="validateForm"
              >
                <option class="py-3" value="Select Plan" selected>
                  Select Plan
                </option>

                <option v-for="plan in plans" :key="plan.id" :value="plan">
                  {{ plan.name }} ₦{{ plan.amount | numeral(0, 0) }}
                </option>
              </select>

              <div
                class="border rounded border-primary flex flex-row items-center px-3 py-1"
              >
                <span class="text-xl">Qty</span>
                <input
                  required
                  type="number"
                  class="form-amount"
                  placeholder="Quantity"
                  v-model="form_data.quantity"
                />
              </div>

              <div
                class="border rounded border-primary flex flex-row items-center px-3 py-1"
              >
                <span class="text-xl">₦</span>
                <input
                  required
                  type="number"
                  class="form-amount"
                  placeholder="Plan Amount"
                  v-model="paymentAmount"
                  step="0.01"
                  @keyup="validateForm"
                />
              </div>
              <!-- <p class="text-red-400">Minimun amount is ₦ {{ min_amount }}</p> -->

              <div class="form-row">
                <button
                  v-if="can_pay == true"
                  class="flex justify-center items-center space-x-3 bg-primary px-6 rounded-lg py-3 text-white font-light w-full mt-8"
                  type="submit"
                >
                  <paystack
                    :amount="paymentAmount * 100"
                    :email="email"
                    :subaccount="hmo.sub_account_code"
                    :paystackkey="paystackkey"
                    :reference="reference"
                    :callback="callback"
                    :close="close"
                    :embed="false"
                  >
                    <span class="">Make Payment</span>
                  </paystack>
                </button>

                <button
                  v-else
                  class="flex justify-center items-center bg-slate-500 px-6 rounded-lg py-3 text-white font-light w-full"
                >
                  Make Payment
                </button>
              </div>
            </div>
          </form>

          <div class="vld-parent">
            <loading
              :active.sync="isLoading"
              loader="dots"
              :can-cancel="true"
              :is-full-page="fullPage"
            ></loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    
    
<script>
import paystack from "vue-paystack";
import axios from "axios";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
    paystack,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      can_pay: false,
      // paystackkey: "pk_test_6555651ecaf3d32f6c6a49dd81f875f06be5f332", //paystack public key
      paystackkey: "pk_live_873084ab876aad40fe062000ee5a706de1b16f30", //paystack public key
      email: "support@hayokmedicare.ng",
      form_data: {
        quantity: 1,
        payment_amount: "",
        plan: "Select Plan",
        new_customer: 1,
        payment_method: "Full Payment",
        searchkey: null,
      },
      user: {
        full_name: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
      },
      plans: [],
      response: "",
      hmo: "",
      okay: false,
      min_amount: 0.0,
      token: "",
    };
  },
  computed: {
    reference() {
      let text = "";
      let possible = "0123456789";

      for (let i = 0; i < 11; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    paymentAmount() {
      if (this.form_data.plan.amount == "Select Plan") {
        return 0.0;
      } else {
        let charges =
          0.015 *
          parseInt(this.form_data.plan.amount * this.form_data.quantity);

        let cappedCharges = charges > 2000 ? 2000 : charges;

        let totalAmount = parseInt(this.form_data.plan.amount *  this.form_data.quantity) + cappedCharges;

        return totalAmount < 2500 ? totalAmount : totalAmount + 100;
      }
    },
  },
  beforeMount() {},
  methods: {
    // validateForm() {
    //   console.log("validator");
    // },
    createToken() {
      axios
        .post(`/api/auth/token/ott`)
        .then((response) => {
          console.log(response);
          this.token = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getHMO() {
      axios
        .post(`/api/hmo-payment`, {
          payment_link: this.$route.params.payment_url,
        })
        .then((response) => {
          console.log(response);
          this.hmo = response.data.data;
          this.plans = response.data.data.plans;
          this.plan = {};

          this.min_amount =
            this.form_data.plan.amount / this.hmo.settings.max_installments;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    validateForm() {
      //get minimum amount
      this.min_amount =
        this.form_data.plan.amount / this.hmo.settings.max_installments;
      //  this.min_amount >= this.form_data.plan.amount &&

      if (this.form_data.new_customer == 1) {
        if (
          this.user.firstname == "" ||
          this.user.lastname == "" ||
          this.user.email == "" ||
          this.form_data.plan == "Select Plan"
        ) {
          this.can_pay = false;
        } else {
          this.can_pay = true;
          // this.form_data.payment_amount = this.form_data.plan.amount;
        }
      } else {
        if (this.user.full_name == "" || this.form_data.plan == "Select Plan") {
          this.can_pay = false;
        } else {
          this.can_pay = true;
          // this.form_data.payment_amount = this.form_data.plan.amount;
        }
      }
    },
    submitForm() {
      this.$router.push("/overview");
    },

    searchUser() {
      if (this.form_data.searchkey.length > 9) {
        axios
          .post(`/api/users/search`, {
            search_key: this.form_data.searchkey,
            type: "single",
          })
          .then((response) => {
            console.log(response);
            this.user = response.data.data;
            // this.user.full_name = response.data.data.full_name;
            // this.token = response.data.token;
          })
          .catch((error) => {
            console.error(error);
            this.user.full_name = "";
            // this.user = "";
            this.can_pay = false;
          });
      } else {
        console.log(this.form_data.searchkey.length);
      }
    },
    callback: function (response) {
      this.isLoading = true;
      axios
        .post(
          `/api/payment_link/verify`,
          {
            new_customer: this.form_data.new_customer,
            plan_id: this.form_data.plan.id,
            reference: response.reference,
            quantity: this.form_data.quantity,
            user_id: this.user.id,
            //new user
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            email: this.user.email,
            phone_number: this.user.phone_number,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$router.push(
            `/transaction-success/${response.data.data.reference}`
          );
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$router.push(`/transaction-failed`);
        });
    },
    close: function () {
      console.log("Payment closed");
    },
  },
  created() {
    this.createToken();
    this.getHMO();
  },
};
</script>
    
    
    <style scoped>
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-slate-500 w-[100%]  md:w-[100%] border border-gray-300;
}

.auth-form-new {
  @apply placeholder:text-slate-500 w-full  md:w-1/2 border border-gray-300;
}
.form-amount {
  @apply py-1 rounded focus:border-none focus:ring-0 my-2 placeholder:text-slate-500 w-[100%]  md:w-[100%]  border-0;
}
.error-search {
  @apply ml-2 text-sm font-thin text-red-800 -mt-2;
}
.success-search {
  @apply ml-2 text-sm font-thin text-green-800 -mt-2;
}
</style>